import React from 'react'

const LinkWithGtag = ({ href, children, ...rest }) => (
  <a
    onClick={() => window.gtag_report_conversion(href)}
    href={href}
    {...rest}
  >
    {children}
  </a>
)

export default LinkWithGtag
