import React from 'react'

import icons from '../../landingsAssets/icons.svg'
import image02 from '../../landingsAssets/TenantExitImages/image02.png'
import image03 from '../../landingsAssets/TenantExitImages/image03.jpg'
import image07 from '../../landingsAssets/TenantExitImages/image07.jpg'
import LandingHeader from '../../components/header/LandingHeader'
import LinkWithGtag from '../../components/landings/LinkWithGtag'

const TenantExit = () => (
  <div id='wrapperLanding'>
    <LandingHeader
      title='FaceFile: One way video interviews to screen candidates faster'
      stylesHref='/styles/tenantExit.css'
    />
    <div id='main'>
      <div class='inner'>
        <div id='container02' class='container columns full screen'>
          <div class='wrapperLanding'>
            <div class='inner'>
              <div>
                <div id='image02' class='image' data-position='center'>
                  <LinkWithGtag class='frame'>
                    <img src={image02} alt='' />
                  </LinkWithGtag>
                </div>
                <h2 id='text14'>Tenant Exit Survey Template</h2>
                <p id='text04'>Video form template</p>
                <p id='text11'>Use this one-way video interview template to get in-depth video feedback from your moving residents to enhance the experience of your existing tenants and minimize turnover. Choose this form, customize, and send it to your tenants in minutes.</p>
              </div>
              <div>
                <p id='text12'>Sign up to use this template for free</p>
                <p id='text03'>Start using this template with our Free Plan and <strong>20 FREE responses</strong> per month.</p>
                <ul id='buttons04' class='buttons'>
                  <li>
                    <a href='https://app.facefile.co/auth/signup' class='button n01'>Sign Up</a>
                  </li>
                </ul>
                <p id='text07'><em>No credit card required.</em></p>
              </div>
            </div>
          </div>
        </div>
        <div id='container03' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' class='container default'>
          <div class='wrapperLanding'>
            <div class='inner' style={{ paddingRight: '9rem', paddingLeft: '9rem' }}>
              <h2 id='text08'>Ready-to-Use Video Form Template</h2>
              <p id='text16'>Get an “in-person feel” for your tenants with beautiful video forms without having to record any videos or come up with the right questions to ask. This video form enables you to understand why tenants are moving out and identify things that tenants liked and disliked the most.</p>
              <div id='image07' class='image' data-position='center'>
                <span class='frame'><img src={image07} alt='' /></span>
              </div>
              <ul id='links01' class='links'>
                <li class='n01'>
                  <LinkWithGtag href='https://app.facefile.co/auth/signup'>
                    Try this template for free
                  </LinkWithGtag>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id='container01' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' class='container columns full screen'>
          <div class='wrapperLanding'>
            <div class='inner'>
              <div>
                <h2 id='text06'>Automate Tenant Exit Surveys</h2>
                <p id='text15'>Listen to the voices of your tenants regularly and with minimal effort. Check how easy it is:</p>
                <div id='list01' class='list'><ul><li><p>You don&#039;t have to think of the <strong>right questions to ask</strong></p></li><li><p>Add more <strong>ready-to-use questions</strong> from our library</p></li><li><p>Create new questions without recording videos with the <strong>text-to-video generator</strong></p></li><li><p><strong>Email link</strong> to tenants via email or integrate this template with your Tenant Management Software</p></li><li><p>All responses are <strong>transcribed into text</strong> automatically.</p></li></ul></div>
                <p id='text10'>You can experience FaceFile as a tenant and interviewer with a quick interactive demo.</p>
                <ul id='buttons06' class='buttons'>
                  <li>
                    <LinkWithGtag href='https://app.facefile.co/onboarding' class='button n01'>
                      Try a demo
                    </LinkWithGtag>
                  </li>
                </ul>
              </div>
              <div>
                <div id='image03' class='image' data-position='center'>
                  <span class='frame'><img src={image03} alt='' /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='container10' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' class='container default full screen'>
          <div class='wrapperLanding'>
            <div class='inner'>
              <h2 id='text32'>How to Use The Tenant Exit Survey Template</h2>
              <p id='text02'>Creating a one-way video survey for your tenants only takes a few minutes from signing up to sharing it with tenants.</p>
            </div>
          </div>
        </div>
        <div id='container07' class='container columns full screen'>
          <div class='wrapperLanding'>
            <div class='inner'>
              <div>
                <ul id='icons02' class='icons'>
                  <li>
                    <LinkWithGtag class='n01' href='https://domain.ext/path' aria-label='Check'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <use xlinkHref={`${icons}#check-light`} />
                      </svg>
                      <span class='label'>Check</span>
                    </LinkWithGtag>
                  </li>
                </ul>
                <p id='text30'>Select a video form template</p>
                <p id='text26'>Select a pre-made form template with pre-recorded video questions. Or customize with questions of your own.</p>
              </div>
              <div>
                <ul id='icons03' class='icons'>
                  <li>
                    <LinkWithGtag class='n01' href='https://domain.ext/path' aria-label='Link'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <use xlinkHref={`${icons}#link`} />
                      </svg>
                      <span class='label'>Link</span>
                    </LinkWithGtag>
                  </li>
                </ul>
                <p id='text31'>Send a link to tenants</p>
                <p id='text27'>Send the link to your tenants or tenant leads. Tenants can self-record video testimonials and responses at their own pace.</p>
              </div>
              <div>
                <ul id='icons01' class='icons'>
                  <li>
                    <LinkWithGtag class='n01' href='https://domain.exhttps://images.ctfassets.net/1d5sc6vl6pww/6OlrMc6hSdcEpQJguTXycl/3900ba2d48eaf8e393865843eb47bab4/icon.pngt/path' aria-label='Star'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <use xlinkHref={`${icons}#star`} />
                      </svg>
                      <span class='label'>Star</span>
                    </LinkWithGtag>
                  </li>
                </ul>
                <p id='text17'>View tenant responses</p>
                <p id='text23'>Sit back, watch video responses, and read response transcripts. Share tenant video responses with your colleagues.</p>
              </div>
            </div>
          </div>
        </div>
        <div id='container11' class='container default full screen'>
          <div class='wrapperLanding'>
            <div class='inner'>
              <h2 id='text01'>Ready to level up your communication with tenants?</h2>
              <p id='text28'>Sign up and try FaceFile with our <strong>Free Plan</strong> and <strong>20 FREE responses</strong> per month.</p>
              <ul id='buttons01' class='buttons'>
                <li>
                  <LinkWithGtag href='https://app.facefile.co/auth/signup' class='button n01'>
                    Get started for free
                  </LinkWithGtag>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TenantExit
