import React from 'react'
import { Helmet } from 'react-helmet'

const LandingHeader = ({ title, stylesHref }) => (
  <Helmet>
    <link
      href='https://fonts.googleapis.com/css?display=swap&family=Inter:700,700italic,500,500italic,400,400italic,900,900italic%7CSource+Sans+Pro:400,400italic,700,700italic'
      rel='stylesheet'
      type='text/css'
    />
    <link rel='stylesheet' href={stylesHref} />
    <title>{title || 'Facefile'}</title>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){
          dataLayer.push(arguments);
        }
      `}
    </script>
    <script type='text/javascript'>
      {`
        function gtag_report_conversion (url) {
          const callback = function () {
            if (typeof (url) !== 'undefined') {
              window.location = url
            }
          }
          gtag('event', 'conversion', {
            send_to: 'AW-10997217694/C_1PCKnGy4YYEJ7z8Pso',
            event_callback: callback
          })
          return false
        }
      `}
    </script>
  </Helmet>
)

export default LandingHeader
